import { useRoutingFlows, ROUTE } from "@upmind-automation/client-vue";

export default {
  register: () => {
    // Register the core routing flows, this is where we customize the routing flows
    const { order } = useRoutingFlows();
    order.register();
  },

  routes: [
    {
      path: "/order/:orderId",
      name: ROUTE.ORDER,
      component: () => import("./Order.vue"),
    },
  ],
};
