import { useRoutingFlows, ROUTE } from "@upmind-automation/client-vue";

export default {
  register: () => {
    // Register the core routing flows, this is where we customize the routing flows
    const { checkout } = useRoutingFlows();
    checkout.register();
  },

  routes: [
    {
      path: "/checkout",
      name: ROUTE.CHECKOUT,
      component: () => import("./Checkout.vue"),
      meta: {
        requiresAuth: true,
        requiresValidCart: true,
      },
    },
  ],
};
