// --- external

// --- internal
import { useClientAddresses } from "../address";
import { useClientPhones } from "../phone";
import { useClientEmails } from "../email";

import { useApi, useSession } from "../..";

// --- utils
import { useValidation } from "../../../utils";
import { includes, filter } from "lodash-es";

// --- types
import type { CompanyEvent, CompanyContext } from "./types";
import type { ClientListingsEvents, ClientListingsContext } from "../types";

// --------------------------------------------------------
// SERVICE METHODS
// Invoked by machines, providing context and event data

// async function getEnums({ field }: CompanyContext, _event: CompanyEvent) {
//   const { getConfig } = useBrand();

//   const brandPaymentPeriod: DefaultPaymentPeriod | any = await getConfig(
//     BrandConfigKeys.PRICE_TAX_PRICE_DEFAULT_PAYMENT_PERIOD
//   ).then(response =>
//     get(response, BrandConfigKeys.PRICE_TAX_PRICE_DEFAULT_PAYMENT_PERIOD)
//   );
// }

async function load(
  _context: ClientListingsContext,
  _event: ClientListingsEvents
) {
  const { get, useUrl } = useApi();
  const { isAuthenticated } = useSession();
  const client = await isAuthenticated().catch(error => Promise.reject(error));

  return get({
    url: useUrl(`clients/${client.id}/companies`, {
      // with: [].join(),
      limit: 0,
    }),
    withAccessToken: true,
    useCache: true,
    refresh: true,
  }).then(({ data }: any) => data);
}

async function loadLookups({ model }: CompanyContext, _event: CompanyEvent) {
  // lets start up/use our dependencies
  const addresses = useClientAddresses();
  const phones = useClientPhones();
  const emails = useClientEmails();

  return Promise.all([
    addresses.isReady(),
    phones.isReady(),
    emails.isReady(),
  ]).then(() => {
    return {
      emails,
      addresses,
      phones,
      baseModel: {
        ...model,
        addressId: addresses.getDefault()?.id,
        email: emails.getDefault()?.email,
        phone: {
          number: phones.getDefault()?.phone?.number,
          nationalNumber: phones.getDefault()?.phone?.national_number,
          countryCallingCode: phones.getDefault()?.phone?.country_calling_code,
          country: phones.getDefault()?.phone?.country,
        },
      },
    };
  });
}

async function filterItems(
  { raw }: ClientListingsContext,
  { data }: ClientListingsEvents
) {
  if (!data?.length)
    return Promise.reject({ error: "No data provided for filtering" });

  const filteredItems = filter(
    raw,
    item =>
      includes(item.state.context?.title?.toLowerCase(), data?.toLowerCase()) ||
      includes(
        item.state.context?.description?.toLowerCase(),
        data?.toLowerCase()
      )
  );

  return Promise.resolve(filteredItems);
}

// --------------------------------------------------------

async function add({ model }: CompanyContext, _event: CompanyEvent) {
  const { post, useUrl } = useApi();
  const { getUserId } = useSession();

  const clientId = await getUserId();

  return post({
    url: useUrl(`clients/${clientId}/companies`),
    data: {
      name: model.name,
      address_id: model.addressId,
      email_id: model.emailId,
      phone_id: model.phoneId,
      reg_number: model.regNumber,
      vat_number: model.vatNumber,
      // vat_percent: model.vatPercent,
    },
    withAccessToken: true,
  }).then(({ data }: any) => data);
}

async function update({ model }: CompanyContext, _event: CompanyEvent) {
  const { put, useUrl } = useApi();
  const { getUserId } = useSession();

  const clientId = await getUserId();

  return put({
    url: useUrl(`clients/${clientId}/companies/${model.id}`),
    data: {
      name: model.name,
      address_id: model.addressId,
      email_id: model.emailId,
      phone_id: model.phoneId,
      reg_number: model.regNumber,
      vat_number: model.vatNumber,
      // vat_percent: model.vatPercent,
    },
    withAccessToken: true,
  }).then(({ data }: any) => data);
}

async function setDefault({ model }: CompanyContext, _event: CompanyEvent) {
  const { put, useUrl } = useApi();
  const { getUserId } = useSession();

  const clientId = await getUserId();

  return put({
    url: useUrl(`clients/${clientId}/companies/${model.id}`),
    data: { default: true },
    withAccessToken: true,
  }).then(({ data }: any) => data);
}

async function remove({ model }: CompanyContext, _event: CompanyEvent) {
  const { del, useUrl } = useApi();
  const { getUserId } = useSession();

  const clientId = await getUserId();

  return del({
    url: useUrl(`clients/${clientId}/companies/${model.id}`),
    withAccessToken: true,
  }).then(({ data }: any) => data);
}

// --------------------------------------------------------

// TODO: async function parse({ model }: PhoneContext, _event: PhoneEvent) {
async function parse({ model }: any, _event: any) {
  // ---
  return Promise.resolve({ model });
}

async function validate(
  { schema, model }: CompanyContext,
  _event: CompanyEvent
) {
  // ---

  // Now validate the model as per normal
  const { validate } = useValidation();

  return new Promise((resolve, reject) => {
    const errors = validate(schema, model);
    if (errors?.length) {
      reject({ error: errors });
    } else {
      resolve(model);
    }
  });
}

// --------------------------------------------------------
// EXPORTS

export default {
  load,
  loadLookups,
  parse,
  validate,
  setDefault,
  add,
  update,
  remove,
  filter: filterItems,
  authSubscription: (context: any, event: any) =>
    useSession().authSubscription(context, event),
  isAuthenticated: () => useSession().isAuthenticated(),
};
