// --- external
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

// --- internal
import LoadingView from "./views/system/Loading.vue";
import NotFound from "./views/system/404.vue";
import { useRoutingEngine } from "@upmind-automation/client-vue";

// --- utils
import { get, isFunction } from "lodash-es";

// --- types
import type { ROUTE } from "@upmind-automation/client-vue";

// -----------------------------------------------------
// Dynamic Routes
const { isReady, resolve, exists } = useRoutingEngine();

const importedRoutes = import.meta.glob<Object>("./views/**/routes.ts", {
  import: "default",
  eager: true,
});

const routes: RouteRecordRaw[] = [];

for (const modules in importedRoutes) {
  const moduleRoutes = get(importedRoutes[modules], "routes", []);
  const register: () => void = get(
    importedRoutes[modules],
    "register",
    () => {}
  );

  if (isFunction(register)) register();

  // No Promises since it's eager loaded.
  routes.push(...moduleRoutes);
}

// -----------------------------------------------------

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "loading",
      component: LoadingView,
    },
    // ---
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFound,
      meta: {
        title: "Page Not Found",
      },
    },
  ],
  scrollBehavior(to, _from, _savedPosition) {
    // handle scroll to anchor on same page
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 108,
      };
    } else {
      // always scroll to top
      return { behavior: "smooth", top: 0 };
    }
  },
});

// -----------------------------------------------------

router.beforeEach(async to => {
  await isReady();
  const route = to?.name as ROUTE;
  // --- Only try resolve if the route exists in our routing engine
  if (exists(route)) {
    const target = await resolve(route, {
      path: to.path,
      params: to.params,
      query: to.query,
    }).catch(() => {
      return undefined;
    });
    //  only redirect if we have a target and it's not the same as the current route
    if (target && target?.name !== route) {
      return target;
    }
  }
});

// -----------------------------------------------------

export default router;
