// --- external

// --- internal
import { useRoutingFlows } from "@upmind-automation/client-vue";

// --- utils

// --- types
import { ROUTE, type Route } from "@upmind-automation/client-vue";
// -----------------------------------------------------------------------------

export default {
  register: (_route: Route) => {
    // Register the core routing flows, this is where we customize the routing flows
    const { product, recommendations } = useRoutingFlows();
    product.register();
    recommendations.register();
  },

  routes: [
    {
      path: "/express/product/add/:pid",
      name: ROUTE.EXPRESS_PRODUCT_ADD,
      component: () => import("./Add.vue"),
    },
    {
      path: "/product/add/:pid",
      name: ROUTE.PRODUCT_ADD,
      component: () => import("./Add.vue"),
    },
    {
      path: "/product/edit/:bpid",
      name: ROUTE.PRODUCT_EDIT,
      component: () => import("./Edit.vue"),
    },
    {
      path: "/product/recommendations",
      name: ROUTE.RECOMMENDATIONS,
      component: () => import("./Recommendations.vue"),
    },
    {
      path: "/product/not-found/",
      name: ROUTE.PRODUCT_NOT_FOUND,
      component: () => import("./NotFound.vue"),
    },
    {
      path: "/product/requires-action/",
      name: ROUTE.PRODUCT_REQUIRES_ACTION,
      component: () => import("./RequiresAction.vue"),
    },
  ],
};
